var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-lg topflop"},[(_vm.axeData && !_vm.alreadyAnswered)?_c('div',[_c('h1',{staticClass:"text-center text-white"},[_vm._v("Axe "+_vm._s(_vm.axeData.ordre)+" : "+_vm._s(_vm.axeData.axe))]),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"accordion accordion-flush border rounded my-3 py-0--5 w-100",attrs:{"id":"accordionResult"}},[_c('div',{staticClass:"accordion-item"},[_c('h2',{staticClass:"accordion-header",attrs:{"id":`headingTop`}},[_c('button',{staticClass:"accordion-button d-flex flex-wrap align-items-baseline",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":`#Top`,"aria-expanded":"true","aria-controls":`collapseTop`}},[_vm._m(0)])]),_c('div',{staticClass:"accordion-collapse collapse show",attrs:{"id":`Top`,"aria-labelledby":`headingTop`,"data-bs-parent":"#accordionResult"}},[_c('div',{staticClass:"accordion-body text-italic d-flex flex-column"},_vm._l((_vm.axeData.leviers),function(item,i){return _c('div',{key:`top${item.id}`},[_c('div',{staticClass:"d-flex align-items-center justify-content-between cursor-pointer",on:{"click":() => _vm.selectTop(item.id)}},[_c('div',{staticClass:"small text-start"},[_vm._v(_vm._s(item.label))]),_c('i',{staticClass:"fa-thumbs-up fa-lg",class:item.id == _vm.answer.topId
                                                ? 'fa-solid text-success'
                                                : 'fa-regular text-gray-500'})]),(i < _vm.axeData.leviers.length - 1)?_c('hr'):_vm._e()])}),0)])])]),_c('div',{staticClass:"accordion accordion-flush border rounded my-3 py-0--5 w-100",attrs:{"id":"accordionResult"}},[_c('div',{staticClass:"accordion-item"},[_c('h2',{staticClass:"accordion-header",attrs:{"id":`headingFlop`}},[_c('button',{staticClass:"accordion-button d-flex flex-wrap align-items-baseline",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":`#Flop`,"aria-expanded":"true","aria-controls":`collapseFlop`}},[_vm._m(1)])]),_c('div',{staticClass:"accordion-collapse collapse show",attrs:{"id":`Flop`,"aria-labelledby":`headingFlop`,"data-bs-parent":"#accordionResult"}},[_c('div',{staticClass:"accordion-body text-italic d-flex flex-column"},_vm._l((_vm.axeData.leviers),function(item,i){return _c('div',{key:`flop${item.id}`},[_c('div',{staticClass:"d-flex align-items-center justify-content-between cursor-pointer",on:{"click":() => _vm.selectFlop(item.id)}},[_c('div',{staticClass:"small text-start"},[_vm._v(_vm._s(item.label))]),_c('i',{staticClass:"fa-thumbs-down fa-lg",class:item.id == _vm.answer.flopId
                                                ? 'fa-solid text-danger'
                                                : 'fa-regular text-gray-500'})]),(i < _vm.axeData.leviers.length - 1)?_c('hr'):_vm._e()])}),0)])])]),_c('button',{staticClass:"btn btn-lg btn-primary",attrs:{"disabled":_vm.answer.topId == 0 || _vm.answer.flopId == 0},on:{"click":_vm.validate}},[_c('i',{staticClass:"fa-solid fa-check me-2"}),_vm._v(" Valider ")])])]):_vm._e(),(_vm.alreadyAnswered)?_c('ConfirmAnsweredView'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-nowrap me-2 text-success fw-bold"},[_c('i',{staticClass:"fa-solid fa-thumbs-up me-3"}),_vm._v(" Je choisis mon top ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-nowrap me-2 text-danger fw-bold"},[_c('i',{staticClass:"fa-solid fa-thumbs-down me-3"}),_vm._v(" Je choisis mon flop ")])
}]

export { render, staticRenderFns }