/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import authService from "../services/authService";

import LoginView from "../views/LoginView.vue";
import HomeView from "../views/HomeView.vue";
import NotFoundView from "../views/NotFoundView.vue";

Vue.use(VueRouter);

function requireAuth(to, from, next) {
    if (!authService.getIfLoggedIn()) {
        next({
            name: "login",
            query: { redirect: to.fullPath },
        });
    } else {
        next();
    }
}

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        beforeEnter: requireAuth
    },
    {
        path: "/login",
        name: "login",
        component: LoginView,
        beforeEnter(to, from, next) {
            if (authService.getIfLoggedIn()) {
                next({ name: "home" });
            } else {
                next();
            }
        },
    },
    {
        path: "/logout",
        beforeEnter(to, from, next) {
            authService.logout();
            next("/login");
        },
    },
    {
        path: "/404-not-found",
        name: "404",
        component: NotFoundView,
    },
    {
        path: "*",
        redirect: { name: "404" },
    },
];

const router = new VueRouter({
    mode: "history",
    base: __dirname,
    routes,
});

export default router;
