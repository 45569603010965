import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCgJKewqssQQ-KNN8VJKjEcX0AwmYgrVck",
    authDomain: "innovevents-dijon-ca0cd.firebaseapp.com",
    databaseURL: "https://innovevents-dijon-ca0cd.firebaseio.com",
    projectId: "innovevents-dijon-ca0cd",
    storageBucket: "innovevents-dijon-ca0cd.appspot.com",
    messagingSenderId: "203743850794",
    appId: "1:203743850794:web:33da86c2b3005d12b5ae53",
    measurementId: "G-KXY83778CD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const functions = getFunctions(app, "europe-west1");
if (process.env.NODE_ENV == "development") {
    console.log("Functions : DEBUG_MODE");
    connectFunctionsEmulator(functions, "192.168.1.16", 5001);
}

const db = getFirestore(app);

const getActiveOldAxeFunction = httpsCallable(functions, "getActiveOldAxe");
const saveTopFlopFunction = httpsCallable(functions, "saveTopFlop");
const getNuageMotsAnsweredFunction = httpsCallable(functions, "getNuageMotsAnswered");
const saveNuageMotsFunction = httpsCallable(functions, "saveNuageMots");
const getActiveNewLevierForPriorityFunction = httpsCallable(functions, "getActiveNewLevierForPriority");
const savePriorityActionFunction = httpsCallable(functions, "savePriorityAction");
const getActiveNewAxeForImplicationFunction = httpsCallable(functions, "getActiveNewAxeForImplication");
const saveImplicationActionFunction = httpsCallable(functions, "saveImplicationAction");


export {
    functions,
    db,
    getActiveOldAxeFunction,
    saveTopFlopFunction,
    getNuageMotsAnsweredFunction,
    saveNuageMotsFunction,
    getActiveNewLevierForPriorityFunction,
    savePriorityActionFunction,
    getActiveNewAxeForImplicationFunction,
    saveImplicationActionFunction
};