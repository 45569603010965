import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../plugins/firebase";

var snapshot = null;

export default {
    name: "game",
    onConfigChanged() { },
    activeSnapshot() {
        if (this.snapshotExists())
            snapshot();

        snapshot = onSnapshot(doc(db, "cebfc_admin", "prod"), (doc) => {
            console.log("Current config: ", doc.data());
            var data = doc.data();
            this.onConfigChanged(data);
        });
        this.snapshotExists = () => true;
    },
    snapshotExists() { return false }
}