<template>
    <div class="container-lg topflop">
        <div v-if="levierData && !alreadyAnswered">
            <h1 class="text-center text-white">Axe {{ levierId.split("_")[0] }} : {{ levierData.axeName }}</h1>
            <div class="text-center">
                <div class="accordion accordion-flush border rounded my-3 py-0--5 w-100" id="accordionResult">
                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headingTop`">
                            <button
                                class="accordion-button d-flex flex-wrap align-items-baseline"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#Top`"
                                aria-expanded="true"
                                v-bind:aria-controls="`collapseTop`"
                            >
                                <span class="me-2 fw-bold d-flex">
                                    <i class="fa-solid fa-star me-3 text-warning"></i>
                                    <span class="flex-fill text-primary"
                                        >Je choisis 1 action innovante à prioriser</span
                                    >
                                </span>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`Top`"
                            class="accordion-collapse collapse show"
                            v-bind:aria-labelledby="`headingTop`"
                            data-bs-parent="#accordionResult"
                        >
                            <div class="accordion-body text-italic d-flex flex-column">
                                <div v-for="(item, i) in levierData.actions" v-bind:key="`top${item.id}`">
                                    <div
                                        class="d-flex align-items-center justify-content-between cursor-pointer"
                                        v-on:click="() => selectPriority(item.id)"
                                    >
                                        <div class="small text-start">{{ item.label }}</div>
                                        <i
                                            v-bind:class="
                                                item.id == answer.priorityId
                                                    ? 'fa-solid text-warning'
                                                    : 'fa-regular text-gray-500'
                                            "
                                            class="fa-star fa-lg"
                                        >
                                        </i>
                                    </div>
                                    <hr v-if="i < levierData.actions.length - 1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button class="btn btn-lg btn-primary" v-on:click="validate" v-bind:disabled="answer.priorityId == 0">
                    <i class="fa-solid fa-check me-2" />
                    Valider
                </button>
            </div>
        </div>
        <ConfirmAnsweredView v-if="alreadyAnswered" />
    </div>
</template>

<script>
import authService from "../services/authService";
import loaderService from "../services/loaderService";
import router from "../plugins/router";
import { getActiveNewLevierForPriorityFunction, savePriorityActionFunction } from "../plugins/firebase";
import ConfirmAnsweredView from "./_ConfirmAnsweredView.vue";

export default {
    components: {
        ConfirmAnsweredView,
    },
    props: {
        levierId: null,
    },
    data() {
        return {
            levierData: null,
            answer: {},
            alreadyAnswered: false,
        };
    },
    async created() {
        loaderService.showLoader();
        if (this.levierId) {
            await getActiveNewLevierForPriorityFunction({ levierId: this.levierId, token: authService.getToken() })
                .then((result) => {
                    const data = result.data;
                    if (data.alreadyAnswered) this.alreadyAnswered = true;
                    else {
                        this.levierData = data;
                        this.answer = { levierId: data.id, priorityId: 0 };
                    }
                })
                .catch(function (error) {
                    console.error("Une erreur est survenue", error.code, error.message, error.details);
                });
        }
        loaderService.hideLoader();
    },
    methods: {
        selectPriority(levierId) {
            this.answer.priorityId = levierId;
        },
        async validate() {
            loaderService.showLoader();
            await savePriorityActionFunction({ token: authService.getToken(), answer: this.answer })
                .then((result) => {
                    const data = result.data;
                    if (data) {
                        this.alreadyAnswered = true;
                    } else {
                        alert("Impossible de sauvegarder votre réponse");
                        router.push({ name: "home" });
                    }
                })
                .catch((error) => {
                    const code = error.code;
                    const message = error.message;
                    const details = error.details;
                    console.log(code, message, details);
                    alert("Impossible de sauvegarder votre réponse");
                });
            loaderService.hideLoader();
        },
    },
};
</script>
