<template>
    <div class="d-flex-center flex-fill">
        <div class="d-flex align-items-center flex-column bg-white bg-opacity-75 p-4 fs-4 text-center">
            <span class="mb-3">En attente d'interaction...</span>
            <span class="small">Vous êtes connecté en tant que</span>
            <span class="fw-bold text-primary mt-3">{{ username }}</span>
            <span
                class="bg-primary rounded-1 fw-medium text-white mt-2 fs-7 px-2 py-1"
                style="letter-spacing: 0.15rem"
                >{{ code }}</span
            >

            <button class="btn btn-secondary-light mt-5" v-on:click="refresh">
                <i class="fa-solid fa-rotate-right me-2"></i>
                Actualiser
            </button>
        </div>
    </div>
</template>

<script>
import authService from "../services/authService";

export default {
    data() {
        return {
            username: authService.getUsername(),
            code: authService.getCode(),
        };
    },
    methods: {
        refresh() {
            window.location.reload();
        },
    },
};
</script>
