<template>
    <div class="login flex-fill d-flex-center py-3">
        <div class="form-signin">
            <form @submit.prevent="login">
                <img style="max-height: 200px; max-width: 300px" src="../assets/img/logo-blanc.png" alt="" />

                <h1 @click="fill" class="h3 mt-4 mb-3 text-medium text-gray-400">CONNEXION</h1>

                <div class="px-3">
                    <input
                        v-model="code"
                        type="text"
                        id="inputCode"
                        class="form-control text-bold text-uppercase"
                        placeholder="Saisir code*"
                        maxlength="15"
                        autofocus
                    />
                </div>
                <p class="text-gray-400 small fs-7 mt-1 mb-2">*code personnel sur votre badge</p>

                <p v-if="error" class="text-danger">Code invalide</p>

                <button class="btn btn-primary btn-lg text-white btn-block my-2">Se connecter</button>
                <p class="mt-1 mb-0 text-gray-400 fs-7">&copy; INNOV'events - {{ new Date().getFullYear() }}</p>
            </form>
        </div>
    </div>
</template>

<script>
import authService from "../services/authService";
import loaderService from "../services/loaderService";

export default {
    data() {
        return {
            code: "",
            error: false,
        };
    },
    methods: {
        async login() {
            loaderService.showLoader();

            await authService.login(this.code, (loggedIn) => {
                if (!loggedIn) {
                    this.error = true;
                } else {
                    this.$router.replace(this.$route.query.redirect || "/");
                }

                loaderService.hideLoader();
            });
        },
        fill() {
            if (process.env.NODE_ENV == "development") {
                this.code = "A198";
            }
        },
    },
};
</script>

<style>
.error {
    color: red;
}
</style>
