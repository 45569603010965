import Vue from "vue";
import App from "./App.vue";
import VueGtag from "vue-gtag";
import router from "./plugins/router";
import "./assets/styles/site.scss";
import "bootstrap/dist/js/bootstrap";
import "@fortawesome/fontawesome-free/css/all.min.css";
import VueCookies from "vue-cookies";

Vue.use(VueGtag, {
    config: { id: "G-RQPKG236HK" },
});
Vue.config.productionTip = false;

Vue.use(VueCookies, { expires: "45d" });
import cookies from "./plugins/cookies";
const hostname = window.location.hostname.replace(/www\./, "");
cookies.set = (key, value) => {
    Vue.$cookies.set(key, value, "45d", null, hostname);
};
cookies.get = (key) => Vue.$cookies.get(key);
cookies.exists = (key) => Vue.$cookies.isKey(key);
cookies.remove = (key) => Vue.$cookies.remove(key, null, hostname);

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
