<!-- https://codesandbox.io/s/vue-countdown-b6k8k -->
<template>
    <div class="countdown">
        <div class="row no-gutters">
            <div class="col countdown__block">
                <span class="countdown__digit">{{ minutes | twoDigits }} : {{ seconds | twoDigits }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        date: null,
    },
    data() {
        return {
            now: Math.trunc(new Date().getTime() / 1000),
            event: this.date,
            finish: false,
        };
    },
    mounted() {
        const _self = this;
        window.setInterval(() => {
            this.now = Math.trunc(new Date().getTime() / 1000);
            if (!this.finish && this.calculatedDate - this.now <= 0) {
                _self.finish = true;
                _self.$emit("onFinish");
            }
        }, 1000);
    },
    computed: {
        secondCount() {
            return this.calculatedDate - this.now;
        },
        calculatedDate() {
            return Math.trunc(Date.parse(this.event) / 1000);
        },
        seconds() {
            if (this.secondCount < 0) return 0;
            return this.secondCount % 60;
        },
        minutes() {
            if (this.secondCount < 0) return 0;
            return Math.trunc(this.secondCount / 60) % 60;
        },
        hours() {
            if (this.secondCount < 0) return 0;
            return Math.trunc(this.secondCount / 60 / 60) % 24;
        },
        days() {
            if (this.secondCount < 0) return 0;
            return Math.trunc(this.secondCount / 60 / 60 / 24);
        },
    },
    filters: {
        twoDigits(value) {
            if (value.toString().length <= 1) {
                return "0" + value.toString();
            }
            return value.toString();
        },
    },
    methods: {
        setTimer(newDate) {
            this.event = newDate;
        },
    },
};
</script>
<style lang="scss"></style>

<!--
// https://codesandbox.io/s/vue-countdown-b6k8k
// utilisation
<Countdown :date="end" @onFinish="finish()"></Countdown> 

// declaration
<script>
import Countdown from "./Countdown.vue";

export default {
    components: {
        Countdown,
    },
    data() {
        return {
            end: new Date("2023-02-07T00:00:00"),
        };
    },
    methods: {
        finish() {
            console.log("finish");
        },
    },
};
</script>
-->
