<template>
    <div class="container-lg nuage-mots">
        <div v-if="loaded && !alreadyAnswered">
            <h1 class="text-center text-white">Comment vous sentez-vous aujourd'hui ?</h1>
            <div class="text-center">
                <div class="my-4">
                    <label class="form-label text-white">Veuillez saisir un mot unique</label>
                    <input class="form-control form-control-lg text-uppercase py-3" type="text" v-model="answer" />
                </div>

                <button class="btn btn-lg btn-primary" v-on:click="validate" v-bind:disabled="!answer">
                    <i class="fa-solid fa-check me-2" />
                    Valider
                </button>
            </div>
        </div>
        <ConfirmAnsweredView v-if="alreadyAnswered" />
    </div>
</template>

<script>
import authService from "../services/authService";
import loaderService from "../services/loaderService";
import router from "../plugins/router";
import { getNuageMotsAnsweredFunction, saveNuageMotsFunction } from "../plugins/firebase";
import ConfirmAnsweredView from "./_ConfirmAnsweredView.vue";

export default {
    components: {
        ConfirmAnsweredView,
    },
    props: {
        axeId: null,
    },
    data() {
        return {
            answer: null,
            alreadyAnswered: false,
            loaded: false,
        };
    },
    async created() {
        loaderService.showLoader();
        await getNuageMotsAnsweredFunction({ token: authService.getToken() })
            .then((result) => {
                const data = result.data;
                if (data.alreadyAnswered) this.alreadyAnswered = true;
            })
            .catch(function (error) {
                console.error("Une erreur est survenue", error.code, error.message, error.details);
            });
        this.loaded = true;
        loaderService.hideLoader();
    },
    methods: {
        selectTop(levierId) {
            this.answer.topId = levierId;
        },
        selectFlop(levierId) {
            this.answer.flopId = levierId;
        },
        async validate() {
            loaderService.showLoader();
            await saveNuageMotsFunction({ token: authService.getToken(), answer: this.answer })
                .then((result) => {
                    const data = result.data;
                    if (data) {
                        this.alreadyAnswered = true;
                    } else {
                        alert("Impossible de sauvegarder votre réponse");
                        router.push({ name: "home" });
                    }
                })
                .catch((error) => {
                    const code = error.code;
                    const message = error.message;
                    const details = error.details;
                    console.log(code, message, details);
                    alert("Impossible de sauvegarder votre réponse");
                });
            loaderService.hideLoader();
        },
    },
};
</script>
