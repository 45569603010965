// globals localStorage
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
import cookies from "../plugins/cookies";

const loginFunction = httpsCallable(functions, "login");

const _getIfLoggedIn = () => cookies.exists("token");

export default {
    name: "auth",

    async login(code, cb) {
        cb = arguments[arguments.length - 1];

        if (_getIfLoggedIn()) {
            if (cb) cb(true);
            this.onChange(true);
            return;
        }

        var params = { code };
        var token = window.btoa(JSON.stringify(params));

        const result = await loginFunction(token)
            .catch(function (error) {
                console.log("Une erreur est survenue", error.code, error.message, error.details);
                cb(false);
                this.onChange(false);
            });

        const data = result.data;
        if (data.token) {
            cookies.set("authenticated", data.authenticated);
            cookies.set("token", data.token);
            cookies.set("username", data.username);
            cookies.set("code", data.code);

            cb(true);
            this.onChange(true);
        } else {
            cb(false);
            this.onChange(false);
        }
    },
    logout(cb) {
        cookies.remove("authenticated");
        cookies.remove("token");
        cookies.remove("username");
        cookies.remove("code");

        if (cb) cb();
        this.onChange(false);
    },
    getToken: () => cookies.get("token"),
    getUsername: () => cookies.get("username"),
    getCode: () => cookies.get("code"),
    getIfLoggedIn: _getIfLoggedIn,
    onChange() { },
};
