<template>
    <div class="home container-lg py-3 d-flex flex-column">
        <!-- <h1 class="text-center text-white">STEP {{ step }}</h1> -->
        <MyCountdown v-if="timerEnd" v-bind:date="timerEnd" @onFinish="timerFinished" :key="`b${componentKey}`" />

        <WaitingView v-if="step == 0" />
        <TopsFlopsView v-else-if="step == 1" :key="`a${componentKey}`" v-bind:axeId="activeId" />
        <NuageMotsView v-else-if="step == 2" :key="`c${componentKey}`" />
        <ActionsPrioritairesView v-else-if="step == 3" :key="`d${componentKey}`" v-bind:levierId="activeId" />
        <ActionsImplicationsView v-else-if="step == 4" :key="`e${componentKey}`" v-bind:axeId="activeId" />
    </div>
</template>

<script>
import WaitingView from "./_WaitingView.vue";
import TopsFlopsView from "./_TopsFlopsView.vue";
import ActionsPrioritairesView from "./_ActionsPrioritairesView.vue";
import ActionsImplicationsView from "./_ActionsImplicationsView.vue";
import MyCountdown from "../components/MyCountdown.vue";
import NuageMotsView from "./_NuageMotsView.vue";
import gameService from "../services/gameService";

export default {
    data() {
        return {
            step: null,
            timerEnd: null,
            componentKey: 0,
            activeId: null,
        };
    },
    components: {
        WaitingView,
        TopsFlopsView,
        MyCountdown,
        NuageMotsView,
        ActionsPrioritairesView,
        ActionsImplicationsView,
    },
    created() {
        gameService.onConfigChanged = (config) => {
            this.step = config.step;

            // si il y a un timer on l'affiche
            const _timerEnd = config.timerEnd?.toDate();
            if (_timerEnd && _timerEnd > new Date()) this.timerEnd = _timerEnd;
            else this.timerEnd = null;

            this.activeId = config.activeId;
            this.activeLevierId = config.activeLevierId;

            //refresh components
            this.componentKey++;
        };
        gameService.activeSnapshot();
    },
    methods: {
        timerFinished() {
            this.timerEnd = null;
            this.step = 0;
            this.activeId = null;
        },
        setTimer() {},
    },
};
</script>
