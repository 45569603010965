<template>
    <div id="app">
        <div v-if="dev_mode" class="position-fixed" style="top: 10px; left: 10px; z-index: 9999">
            <span class="badge bg-primary d-block d-sm-none">xs</span>
            <span class="badge bg-secondary d-none d-sm-block d-md-none">sm</span>
            <span class="badge bg-success d-none d-md-block d-lg-none">md</span>
            <span class="badge bg-dark d-none d-lg-block d-xl-none">lg</span>
            <span class="badge bg-info d-none d-xl-block">xl</span>
        </div>
        <router-link
            v-if="isLoggedIn"
            to="/logout"
            class="position-fixed"
            style="top: 20px; right: 20px; z-index: 9999"
        >
            <i class="fa-solid fa-power-off fa-xl text-secondary"></i>
        </router-link>
        <div v-if="isLoading" class="loader d-flex-center">
            <div class="d-flex-center flex-column bg-dark bg-opacity-75 rounded px-3 pt-4--5 pb-3">
                <div class="spinner-border text-white" role="status"></div>
                <span class="text-white mt-3">Chargement...</span>
            </div>
        </div>
        <router-view ref="view" class="flex-fill body-container"></router-view>
    </div>
</template>
<script>
import authService from "./services/authService";
import loaderService from "./services/loaderService";

export default {
    data() {
        return {
            dev_mode: process.env.NODE_ENV == "development",
            isLoading: false,
            isLoggedIn: authService.getIfLoggedIn(),
        };
    },
    created() {
        authService.onChange = (loggedIn) => {
            this.isLoggedIn = loggedIn;
        };

        loaderService.showLoader = () => (this.isLoading = true);
        loaderService.hideLoader = () => (this.isLoading = false);
    },
};
</script>
