<template>
    <div class="container-lg topflop">
        <div v-if="axeData && !alreadyAnswered">
            <h1 class="text-center text-white">Axe {{ axeData.id }} : {{ axeData.axeName }}</h1>
            <div class="text-center">
                <div
                    class="accordion accordion-flush border rounded my-3 py-0--5 w-100"
                    v-for="levier in axeData.leviers"
                    v-bind:key="`${levier.id}${componentKey}`"
                    id="accordionResult"
                >
                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`heading${levier.id}`">
                            <button
                                class="accordion-button d-flex flex-wrap align-items-baseline"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#${levier.id}`"
                                aria-expanded="true"
                                v-bind:aria-controls="`collapse${levier.id}`"
                            >
                                <span class="me-2 fw-bold d-flex">
                                    <i class="fa-solid fa-hand me-3 text-primary"></i>
                                    <span class="flex-fill text-primary">
                                        Je choisis 1 action innovante à laquelle je souhaite contribuer
                                    </span>
                                </span>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`${levier.id}`"
                            class="accordion-collapse collapse show"
                            v-bind:aria-labelledby="`heading${levier.id}`"
                            data-bs-parent="#accordionResult"
                        >
                            <div class="accordion-body text-italic d-flex flex-column">
                                <div v-for="(item, i) in levier.actions" v-bind:key="`action${item.id}`">
                                    <div
                                        class="d-flex align-items-center justify-content-between cursor-pointer"
                                        v-on:click="() => selectImplication(levier.id, item.id)"
                                    >
                                        <div class="small text-start">{{ item.label }}</div>
                                        <i
                                            v-bind:class="
                                                item.id == answers[levier.id]
                                                    ? 'fa-solid text-secondary'
                                                    : 'fa-regular text-gray-500'
                                            "
                                            class="fa-hand fa-lg"
                                        >
                                        </i>
                                    </div>
                                    <hr v-if="i < levier.actions.length - 1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button class="btn btn-lg btn-primary" v-on:click="validate">
                    <i class="fa-solid fa-check me-2" />
                    Valider
                </button>
            </div>
        </div>
        <ConfirmAnsweredView v-if="alreadyAnswered" />
    </div>
</template>

<script>
import authService from "../services/authService";
import loaderService from "../services/loaderService";
import router from "../plugins/router";
import { getActiveNewAxeForImplicationFunction, saveImplicationActionFunction } from "../plugins/firebase";
import ConfirmAnsweredView from "./_ConfirmAnsweredView.vue";

export default {
    components: {
        ConfirmAnsweredView,
    },
    props: {
        axeId: null,
    },
    data() {
        return {
            axeData: null,
            answers: {},
            componentKey: 0,
            alreadyAnswered: false,
        };
    },
    async created() {
        loaderService.showLoader();
        if (this.axeId) {
            await getActiveNewAxeForImplicationFunction({ axeId: this.axeId, token: authService.getToken() })
                .then((result) => {
                    const data = result.data;
                    console.log(data);
                    if (data.alreadyAnswered) this.alreadyAnswered = true;
                    else {
                        this.axeData = data;
                    }

                    this.axeData.leviers.forEach((levier) => {
                        this.answers[levier.id] = 0;
                    });
                })
                .catch(function (error) {
                    console.error("Une erreur est survenue", error.code, error.message, error.details);
                });
        }
        loaderService.hideLoader();
    },
    methods: {
        selectImplication(levierId, implicationId) {
            console.log("levierId", levierId);
            console.log("this.answers[levierId]", this.answers[levierId]);
            this.answers[levierId] = implicationId;
            console.log("this.answers[levierId]", this.answers[levierId]);
            this.componentKey++;
        },
        async validate() {
            loaderService.showLoader();
            await saveImplicationActionFunction({
                token: authService.getToken(),
                axeId: this.axeId,
                answers: this.answers,
            })
                .then((result) => {
                    const data = result.data;
                    if (data) {
                        this.alreadyAnswered = true;
                    } else {
                        alert("Impossible de sauvegarder votre réponse");
                        router.push({ name: "home" });
                    }
                })
                .catch((error) => {
                    const code = error.code;
                    const message = error.message;
                    const details = error.details;
                    console.log(code, message, details);
                    alert("Impossible de sauvegarder votre réponse");
                });
            loaderService.hideLoader();
        },
    },
};
</script>
